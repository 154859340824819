<template>
  <v-menu :close-on-click="closeOnClick">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined tile block v-bind="attrs" v-on="on">
        {{$t('buttons.create')}}
        <v-icon right dark>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item v-for="(item, index) in createItems" :key="index" :to="item.url">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
          <v-divider class="hidden-sm-and-down"></v-divider>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: "MenuDropdown",
    data() {
      return {
        createItems: [
          {title: this.$t('menu-dropdown.order'), icon: 'mdi-clipboard-list-outline', url: '/createorder'},
          {title: this.$t('menu-dropdown.task'), icon: 'mdi-calendar-check-outline', url: '/createtask'},
          {title: this.$t('menu-dropdown.manager'), icon: 'mdi-account-details', url: '/createmanager'},
          {title: this.$t('menu-dropdown.payer'), icon: 'mdi-account-multiple', url: '/createpayer'},
          {title: this.$t('menu-dropdown.agency'), icon: 'mdi-domain', url: '/createagency'},
        ],
        closeOnClick: true,
      }
    },
  }
</script>
