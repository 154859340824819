<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-container fluid>
          <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px"></v-img>
          <v-card-title>
            <span class="headline" style="text-transform: uppercase">{{$t('dialogs.replace-file.replace-file')}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-file-input v-model="newFile" prepend-icon="mdi-attachment-plus" show-size clearable
                          :placeholder="$t('dialogs.replace-file.select-file')" outlined>
            </v-file-input>
            <v-btn color="blue darken-1" @click="sendFile" :disabled="!newFile || loading" :loading="loading">
              {{$t('buttons.replace')}}
            </v-btn>

            <v-container>
              <v-progress-linear v-if="loading" v-model="progress" color="blue" height="27">
                <strong>{{$t('loadings.loading-progress')}} {{ progress }}%</strong>
              </v-progress-linear>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              v-on:click="$emit('close-dialog')">
              {{$t('buttons.close')}}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {AXIOS} from "../../../http-commons";

  export default {
    props: ['open-dialog', 'orderId', 'file'],
    name: "ReplaceFileDialog",
    data: () => ({
      loading: false,
      progress: 0,
      newFile: null
    }),
    methods: {
      async sendFile() {
        this.loading = true;
        let action = 'replace'
        let objectType = 'File'
        let oldFile = this.file;
        let newFile = this.newFile;
        this.$emit('remove-from-fs', oldFile);
        const formData = new FormData();
        formData.append('file', this.newFile);
        formData.append('fileId', oldFile.fileId);
        formData.append('fileName', newFile.name);
        formData.append('description', oldFile.description);
        formData.append('orderId', this.orderId);
        formData.append('fileExtension', newFile.name.split('.').pop());
        formData.append('fileSize', newFile.size);
        formData.append('userId', this.currentUser.id);
        formData.append('fileType', oldFile.fileType);
        await AXIOS.post('/api/fileUpload/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => this.progress = (progressEvent.loaded * 100 / progressEvent.total).toFixed(0)
        })
          .then(response => {
            this.userNotification(response.data, action, objectType);
            this.$emit('refetch-file');
            this.loading = false;
            this.$emit('close-dialog');
          })
      },
    },
  }
</script>

<style scoped>

</style>
