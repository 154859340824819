<template>
  <Loader type="file" v-if="loading"></Loader>
  <v-container fluid v-else-if="!loading">
    <v-row justify="center" class="ma-auto">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <v-row class="ma-auto">
              <v-col>
                <div class="font-weight-light" style="text-transform: uppercase">
                  <h3>{{file.fileName}}</h3>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-chip label text-color="white" style="float: right" color="indigo">
                  {{file.fileType}}
                </v-chip>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="ma-auto">
              <v-col>
                <div class="font-weight-light" style="text-transform: uppercase">
                  {{$t('file-card.order')}}:
                  <router-link :to="{ path: '/order/' + order.orderId}"
                               style="color: white" class="no-underline-link">
                    <h3>{{ order.orderCode }}</h3>
                  </router-link>
                </div>
              </v-col>
              <v-col>
                <div class="font-weight-light" style="float: right">
                  {{$t('file-card.size')}}:
                  <h3>{{calculateSize}}</h3>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="ma-auto">
              <v-col sm="12" md="6">
                <MenuDropdown></MenuDropdown>
              </v-col>
              <v-col sm="12" md="6">
                <ActionButtonGroup v-on:enable-edit="readonly=false"
                                   v-on:clone-dialog="cloneDialog=true"></ActionButtonGroup>
              </v-col>
            </v-row>
          </template>

          <v-container class="py-0">
            <v-row class="ma-auto">
              <v-col cols="12" md="4">
                <v-row class="ma-auto">
                  <v-img v-if="file.fileExtension ==='zip'|| file.fileExtension ==='ZIP'"
                         :src="require(`@/assets/filelogos/zip.svg`)"
                         max-height="100" contain></v-img>
                  <v-img v-if="file.fileExtension ==='rar'|| file.fileExtension ==='RAR'"
                         :src="require(`@/assets/filelogos/rar.svg`)"
                         max-height="100" contain></v-img>
                  <v-img v-if="file.fileExtension ==='png'|| file.fileExtension ==='PNG'"
                         :src="fileIcon.icon" max-height="300" contain></v-img>
                  <v-img v-if="file.fileExtension ==='bmp'|| file.fileExtension ==='BMP'"
                         :src="fileIcon.icon"
                         max-height="300" contain></v-img>
                  <v-img v-if="file.fileExtension ==='pdf'|| file.fileExtension ==='PDF'"
                         :src="require(`@/assets/filelogos/pdf.png`)"
                         max-height="100" contain></v-img>
                  <v-img v-if="file.fileExtension ==='gif' || file.fileExtension ==='GIF'"
                         :src="require(`@/assets/filelogos/gif.svg`)"
                         max-height="100" contain></v-img>
                  <v-img v-if="file.fileExtension ==='html'" :src="require(`@/assets/filelogos/html.svg`)"
                         max-height="100" contain></v-img>
                  <v-img v-if="file.fileExtension ==='txt'|| file.fileExtension ==='TXT'"
                         :src="require(`@/assets/filelogos/txt.svg`)"
                         max-height="100" contain></v-img>
                  <v-img v-if="file.fileExtension ==='psd'|| file.fileExtension ==='PSD'"
                         :src="require(`@/assets/filelogos/psd.svg`)"
                         max-height="100" contain></v-img>
                  <!--                  <v-img-->
                  <!--                    v-if="file.fileExtension ==='jpg' || file.fileExtension ==='jpeg' || file.fileExtension ==='JPG' || file.fileExtension ==='JPEG'"-->
                  <!--                    :src="fileIcon.icon"-->
                  <!--                    max-height="300" contain></v-img>-->
                  <v-img
                    v-if="file.fileExtension ==='jpg' || file.fileExtension ==='jpeg' || file.fileExtension ==='JPG' || file.fileExtension ==='JPEG'"
                    :src="imageSrc" max-height="300" contain></v-img>
                  <v-img
                    v-if="file.fileExtension ==='ppt' || file.fileExtension ==='pptx'||file.fileExtension ==='PPT' || file.fileExtension ==='PPTX'"
                    :src="require(`@/assets/filelogos/ppt.png`)" max-height="100" contain></v-img>
                  <v-img
                    v-if="file.fileExtension ==='xls' || file.fileExtension ==='xlsx'||file.fileExtension ==='XLS' || file.fileExtension ==='XLSX'"
                    :src="require(`@/assets/filelogos/excel.png`)" max-height="100" contain></v-img>
                  <v-img
                    v-if="file.fileExtension ==='doc' || file.fileExtension ==='docx'|| file.fileExtension ==='DOC' || file.fileExtension ==='DOCX'"
                    :src="require(`@/assets/filelogos/word.png`)" max-height="100" contain></v-img>
                </v-row>

                <v-row justify="center" align="center">
                  <v-btn-toggle color="primary" v-model="defaultButtonPressed">
                    <!--                    <v-btn :href="fileIcon.icon">-->
                    <!--                    <v-btn :href="imageSrc">-->
                    <v-btn v-on:click="downloadFile">
                      <v-icon>mdi-download</v-icon>
<!--                      <a :download="file.fileName" style="color: white"> </a>-->
                    </v-btn>
                    <v-btn v-on:click="replaceFileDialog=true">
                      <v-icon>mdi-file-replace-outline</v-icon>
                    </v-btn>
                    <v-btn v-on:click="deleteDialog=true">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-row>
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea v-model="file.description" class="purple-input" :label="$t('file-card.description')" counter
                            rows="4" :readonly="readonly"/>
                <v-autocomplete
                  v-model="file.fileType" :items="fileTypes" :label="$t('file-card.file-type')" required
                  :readonly="readonly">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="ma-auto">
              <v-col md="6" sm="6">
                <v-btn outlined text block color="secondary"
                       :to="{ path: '/order/' + order.orderId}">
                  {{$t('buttons.back')}}
                </v-btn>
              </v-col>
              <v-col md="6" sm="6">
                <v-btn :disabled="readonly" block color="primary" @click="updateFile">
                  {{$t('buttons.update')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </base-material-card>
      </v-col>

      <v-col cols="12" md="4">
        <base-material-card class="v-card-profile" color="primary">
          <template v-slot:heading>
            <v-row>
              <v-col cols="12" md="6" sm="6" lg="6">
                <h3 style="text-transform: uppercase">
                  {{ file.fileName }}
                </h3>
                <h4 style="text-transform: uppercase">
                  {{ order.orderCode }}
                </h4>
              </v-col>
            </v-row>
          </template>

          <v-list three-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle v-if="file.description"
                                      :v-text="$t('file-card.description')+': '+ file.description"></v-list-item-subtitle>
                <v-list-item-subtitle v-text="$t('file-card.size')+': '+ calculateSize"></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-text="$t('file-card.created-date')+': '+ parseTime(file.createdTime)"></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-text="$t('file-card.modified-date')+': '+ parseTime(file.modifiedTime)"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </base-material-card>
      </v-col>
    </v-row>
    <ReplaceFileDialog v-bind:open-dialog="replaceFileDialog" :orderId="order.orderId" :file="file"
                       v-on:close-dialog="replaceFileDialog=false" v-on:refetch-file="getFile"
                       v-on:remove-from-fs="removeFileFromFS"></ReplaceFileDialog>
    <DeleteConfirmationDialog :object-name="'file'" :file="file"
                              v-on:close-dialog="deleteDialog=false" v-bind:open-dialog="deleteDialog"
                              v-on:delete-object="deleteObject"></DeleteConfirmationDialog>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import ActionButtonGroup from "../../components/buttons/ActionButtonGroup";
  import MenuDropdown from "../dashboard/component/MenuDropdown";
  import ReplaceFileDialog from "./ReplaceFileDialog";
  import DeleteConfirmationDialog from "../../components/dialogs/DeleteConfirmationDialog";
  import Loader from "../../components/Loaders/Loader";
  import {useFiles} from "../../stores/filesStore";
  import {mapState} from "pinia";

  const fileStore = useFiles();

  let moment = require('moment');
  export default {
    name: "FileCard",
    components: {DeleteConfirmationDialog, ReplaceFileDialog, MenuDropdown, ActionButtonGroup, Loader},
    data() {
      return {
        imageSrc: '',
        loading: false,
        replaceFileDialog: false,
        deleteDialog: false,
        file: [],
        order: [],
        disabled: true,
        readonly: true,
        createdTime: '',
        modifiedTime: '',
        defaultButtonPressed: null,
        fileTypes: [
          this.$t('file-types.original'),
          this.$t('file-types.translated'),
          this.$t('file-types.reference'),
          this.$t('file-types.tm'),
          this.$t('file-types.glossary'),
          this.$t('file-types.finance'),
          this.$t('file-types.other'),
        ]
      }
    },
    computed: {
      ...mapState(useFiles, ['files']),
      calculateSize() {
        let size;
        if (this.file.fileSize / 1000 >= 1000) {
          size = (this.file.fileSize / 1000000).toFixed(1) + ' ' + this.$t('units.mb')
        }
        if (this.file.fileSize / 1000 < 1000) {
          size = (this.file.fileSize / 1000).toFixed(1) + ' ' + this.$t('units.kb')
        }
        return size;
      },
      // fileIcon() {
      //
      //   const path = `${this.file.filePath}`;
      //   console.log("pat: " + path)
      //   const path2 = `C:\\Users\\Андрій\\SQO60149\\front.jpg`;
      //
      //   return {
      //     ...this.file,
      //     // icon: this.file.fileName && require(`@/assets/order-files/${this.order.orderCode}/${this.file.fileName}`)
      //     // icon: this.file.fileName && require(`${this.file.fileName}`)
      //     icon: this.file.fileName && require(`C:\\Users\\Андрій\\SQO60149\\front.jpg`)
      //   }
      // },
    },
    methods: {
      downloadFile() {
        AXIOS({
          //url: 'C:\\Users\\Андрій\\Downloads\\KTN\\05-07\\ED for LH20230210-2.pdf', // File URL Goes Here
          method: 'GET',
          responseType: 'blob',
        }).then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));
          var docUrl = document.createElement('x');
          docUrl.href = FILE;
          docUrl.setAttribute('download', 'file.ext');
          document.body.appendChild(docUrl);
          docUrl.click();
        });
      },
      getEntities() {
        this.order = this.file.order;
      },
      updateFile() {
        this.loading = true;
        let action = 'update';
        let objectType = 'File'
        let file = this.file;
        let params = new URLSearchParams();
        params.append('fileId', file.fileId);
        params.append('description', file.description);
        console.log("typ: " + file.fileType)
        params.append('fileType', file.fileType);
        AXIOS.put(`/api/updateFile`, params)
          .then(response => {
            this.userNotification(response.data, action, objectType);
            this.readonly = true;
            this.loading = false;
          })
      },
      parseTime(time) {
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
      getFile() {
        this.loading = true;
        AXIOS.get('/api/file/' + this.$route.params.fileId)
          .then(response => {
            this.file = response.data;
            // import(this.file.filePath)
            // import(`C:\\Users\\Андрій\\Downloads\\KTN\\05-07\\ED for LH20230210-2.pdf`)
            //   .then(image => {
            //     this.imageSrc = image.default;
            //     console.log("src: " + this.imageSrc)
            //   });
            this.getEntities()
          })
          .catch(e => {
            this.errors.push(e)
          })
        this.loading = false;
      },
      deleteObject() {
        let file = this.file;
        let action = 'delete';
        let objectType = 'File'
        fileStore.deleteFile(file.fileId)
        this.removeFileFromFS(file)
        let params = new URLSearchParams();
        params.append('fileId', file.fileId);
        AXIOS.delete('/api/deleteFile', {params});
        this.userNotification(this.response, action, objectType);
        this.$router.push({path: '/order/' + this.order.orderId})
      },
      removeFileFromFS(file) {
        let params = new URLSearchParams();
        params.append('fileId', file.fileId);
        AXIOS.post('/api/removeFileFromFS', params)
          .then(response => {
            this.response = response.data;
          })
      },
    },
    created() {
      this.getFile();
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
