<template>
  <v-menu    :close-on-click="closeOnClick">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined tile block v-bind="attrs" v-on="on" style="text-transform: uppercase">
        {{$t('buttons.options')}}
        <v-icon right dark> mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list nav dense>
      <v-list-item-group>
        <v-list-item v-if="item.object===object || item.object===''"
                     :color="item.color"
                     v-for="(item, index) in items"
                     :key="index" v-on:click="$emit(item.action)">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
          <v-divider class="hidden-sm-and-down"></v-divider>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    props: ['object', 'agencyId'],
    name: "ActionButtonGroup",
    data() {
      return {
        items: [
          {title: this.$t('action-button-group.edit'), icon: 'mdi-pencil', action: 'enable-edit', color: 'primary', object: ''},
          {
            title: this.$t('action-button-group.debt-discharge'),
            icon: 'mdi-credit-card-marker-outline',
            action: 'open-debt-discharge-dialog',
            color: 'purple darken-4',
            object: 'isAgency'
          },
          {
            title: this.$t('action-button-group.invoicing'),
            icon: 'mdi-credit-card-marker-outline',
            action: 'create-agency-invoice',
            color: 'purple darken-4',
            object: 'isAgency'
          },
          {title: this.$t('action-button-group.clone'), icon: 'mdi-content-duplicate', action: 'clone-dialog', color: 'indigo', object: ''},
          {title: this.$t('action-button-group.history'), icon: 'mdi-history', action: 'show-history', color: 'orange', object: 'order'},
          {title: this.$t('action-button-group.delete'), icon: 'mdi-delete', action: 'open-delete-dialog', color: 'red', object: ''},
          {
            title: this.$t('action-button-group.change-logo'),
            icon: 'mdi-image-edit-outline',
            action: 'open-update-logo-dialog',
            color: 'amber',
            object: 'isAgency'
          },
        ],
        closeOnClick: true,
      }
    },
  }
</script>
